
import { defineComponent } from "vue";
import CreateEditDialog from "@/components/controls/catalogueGrid/CreateEditDialog.vue";
import mountComponentMixin from "@/mixins/MountComponentMixin";
import TextControl from "@/components/controls/base/TextControl.vue";

export default defineComponent({
  components: {
    CreateEditDialog,
    TextControl,
  },
  props: {
    saveModelCallback: { required: true },
  },
  mixins: [mountComponentMixin],
  methods: {
    dialog() {
      return this.$refs.dialog as any;
    },
    open(model: any, editType: any) {
      this.dialog().open(model, editType);
    },
    close() {
      this.dialog().close();
    },
    save() {
      this.dialog().save();
    },
    onShown(model: any) {
      (this as any).mountComponent(
        "components/catalogues/DisplacementCatalogue/DisplacementLongitudinalSubCatalogueGrid.vue",
        (this as any).longitudinalSubGridDomId,
        {
          parentModel: model,
        }
      );

      (this as any).mountComponent(
        "components/catalogues/DisplacementCatalogue/DisplacementTransverseSubCatalogueGrid.vue",
        (this as any).transverseSubGridDomId,
        {
          parentModel: model,
        }
      );
    },
    beforeUnmount() {
      (this as any).unmountComponent((this as any).longitudinalSubGridDomId);
      (this as any).unmountComponent((this as any).transverseSubGridDomId);
    },
  },
  data() {
    return {
      longitudinalSubGridDomId:
        "DisplacementCreateEditDialog_longitudinalSubCatalogueGrid",
      transverseSubGridDomId:
        "DisplacementCreateEditDialog_transverseSubCatalogueGrid",
    };
  },
});
